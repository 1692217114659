import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { App, Ticket, Redirect, Done, Failed, Finish, NotFound } from './pages'

class Routes extends React.Component {
  render() {
    return (
      <App>
        <Switch>
          <Route name="Redirect" exact path="/" component={Redirect} />
          <Route name="Ticket" path="/tickets/:ticketId" component={Ticket} />
          <Route name="Pos" path="/pos/:ticketId" component={Ticket} />
          <Route name="Done" path="/done" component={Done} />
          <Route name="Failed" path="/failed" component={Failed} />
          <Route name="Finish" path="/finish" component={Finish} />
          <Route name="NotFound" path="*" component={NotFound} />
        </Switch>
      </App>
    )
  }
}

export default Routes
