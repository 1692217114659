import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import firebase from 'firebase/app'

class Redirect extends React.Component {
  validateCode($inputs) {
    var code = ''

    for (let $input of $inputs) {
      if (!$input.value) {
        return false
      } else {
        code += $input.value
      }
    }

    return code.length === 6 ? code.toLowerCase() : false
  }

  addInputHandlers($inputs) {
    $inputs.forEach(($input, index) => {
      $input.onkeyup = () => {
        let nextIndex = 5

        if (index < 5) {
          nextIndex = index + 1
        }

        $input.classList.add('active')
        if ($input.getAttribute && $input.value.length === 1) {
          $inputs[nextIndex].focus()
          $inputs[nextIndex].classList.add('active')
        }

        const ticketId = this.validateCode($inputs)

        if (ticketId) {
          firebase
            .firestore()
            .collection('Tickets')
            .doc(ticketId)
            .get()
            .then(doc => {
              if (doc.exists) {
                document.body.classList.add('loading')
                document.body.classList.remove('invalid')

                $inputs.forEach(($input, index) => {
                  $input.disabled = true
                })

                this.props.history.push(`/tickets/${ticketId}`)
              } else {
                throw 'doc does not exist'
              }
            })
            .catch(err => {
              document.body.classList.add('invalid')
              setTimeout(() => {
                document.body.classList.remove('invalid')
              }, 3000)
            })
        }
      }

      $input.onkeydown = e => {
        const letters = /^[A-Za-z]+$/
        const numbers = /^\d+$/

        let prevIndex = 0

        if (index > 0) {
          prevIndex = index - 1
        }

        if (e.keyCode === 13 || e.keyCode === 8) {
          if ($input.value.length === 0) {
            $inputs[prevIndex].focus()
          }
        } else if ($input.getAttribute('type') === 'text') {
          if (!e.key.match(letters)) {
            return false
          }
        } else if ($input.getAttribute('type') === 'number') {
          if (!e.key.match(numbers)) {
            return false
          }
        }
        if ($input.value.length === 1) {
          $input.value = ''
        }

        if (index === 0) {
          $inputs[0].setAttribute('placeholder', '')
          $inputs[1].setAttribute('placeholder', '')
          $inputs[2].setAttribute('placeholder', '')
        }

        if (index === 3) {
          $inputs[3].setAttribute('placeholder', '')
          $inputs[4].setAttribute('placeholder', '')
          $inputs[5].setAttribute('placeholder', '')
        }
      }

      $input.onblur = () => {
        $input.classList.remove('active')
      }

      $input.onclick = () => {
        $input.select()
        $input.classList.add('active')
      }
    })
  }

  componentDidMount() {
    const $inputs = document.querySelectorAll('input')
    this.addInputHandlers($inputs)

    window.setTimeout(() => {
      document.body.classList.remove('preload')
    }, 1500)
  }

  render() {
    return (
      <div className="wrap">
        <Helmet bodyAttributes={{ class: ['page-redirect preload'] }} />
        <div className="view">
          <div className="logo" />
          <div className="box">
            <div className="row">
              <input type="number" maxLength="1" placeholder="1" />
              <input type="number" maxLength="1" placeholder="2" />
              <input type="number" maxLength="1" placeholder="3" />
            </div>
            <div className="divider">
              <svg width="32px" height="6px" viewBox="0 0 32 6" version="1.1">
                <g id="ppay.nl" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                  <g
                    transform="translate(-142.000000, -264.000000)"
                    fillRule="nonzero"
                    stroke="#79B5D1"
                    strokeWidth="5"
                  >
                    <path d="M144.5,267.475466 L171.425781,267.475466" id="Line-2" />
                  </g>
                </g>
              </svg>
              <svg className="invalid" width="32px" height="6px" viewBox="0 0 32 6" version="1.1">
                <g id="ppay.nl" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                  <g
                    transform="translate(-142.000000, -264.000000)"
                    fillRule="nonzero"
                    stroke="#FF565A"
                    strokeWidth="5"
                  >
                    <path d="M144.5,267.475466 L171.425781,267.475466" id="Line-2" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="row">
              <input type="text" maxLength="1" placeholder="a" />
              <input type="text" maxLength="1" placeholder="b" />
              <input type="text" maxLength="1" placeholder="c" />
            </div>
          </div>
          <a href="https://printnpay.nl">printnpay.nl</a>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(state => state)(Redirect))
