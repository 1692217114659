import fetch from 'node-fetch'

export function getTicket(ticketId) {
  return fetch(`/api/tickets/${ticketId}`)
    .then(resp => Promise.all([resp.json(), resp.ok]))
    .then(([data, ok]) => {
      if (!ok) {
        throw data
      }

      return data
    })
}

export function createPayment(ticketId, bank) {
  const body = JSON.stringify({ ticketId, bank })
  return fetch(`/api/payments`, { method: 'POST', body })
    .then(resp => Promise.all([resp.json(), resp.ok]))
    .then(([data, ok]) => {
      if (!ok) {
        throw data
      }

      return data
    })
}
