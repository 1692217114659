const CONFIG = {
  '1' : {
    client: {
        name: "Pizza di Mama",
        logo: "/static/dimama.svg",
        colors: ['#00853e', '#cd1718'],
    },
    vat_percentage: '6',
    payment_methods: ['ideal'],
    finish_message: `
      <h2>Bedankt en..</h2>
      <h3>Buon Appetito</h3>
      <h4>🍝 🍕 🍷</h4>`
    ,
    features: {
        feedback: {
            label: "Hoe beoordeelt u onze levering?",
            comment: true,
        },
        download_receipt_option: true
    }
  },
  '2' : {
    client: {
        name: "LeisureLands",
        logo: "/static/leisurelands.svg",
        colors: ['#00A19B', '#002D73'],
    },
    vat_percentage: '21',
    payment_methods: ['ideal'],
    finish_message: 'Hartelijk dank voor uw bezoek. Gebruik nu de onderstaande knop om de slagboom te openen.',
    features: {
      download_receipt_option: true
    }
  },
};

export { CONFIG };
