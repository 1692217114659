import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import qs from 'qs'
import { CONFIG } from '../../config'

class Finish extends React.Component {
  componentDidMount() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 50 })
    if(query.source) firebase
      .firestore()
      .collection('Payments')
      .doc(query.source)
      .onSnapshot(doc => {
        const payment = doc.data()

        console.log(payment)
        if (payment.status === 'succeeded') {
          this.props.history.push('/done')
        } else if (payment.status === 'pending' || payment.status === 'chargeable') {
          console.log('wait')
        } else {
          this.props.history.push('/failed')
        }
      })
  }

  render() {
    return <div className="loading-screen">
            <img src={CONFIG.client.logo} alt={CONFIG.client.name} />
            <p>Uw bon wordt geladen..</p>
          </div>

  }
}

export default withRouter(connect(state => state)(Finish))
