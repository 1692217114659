import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, compose(applyMiddleware(thunkMiddleware)))

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
