import React from 'react'
import { Helmet } from 'react-helmet'
import { CONFIG } from '../../config'

class Done extends React.Component {
  state = {
    rating: null,
    ratingId: null,
    feedbackSent: false,
    comment: '',
  }

  onRate(rating) {
    this.setState({ rating })
    /* const updates = {}
    updates['/ratings/' + this.state.ratingId] = { rating, date: +new Date() }
    firebase
      .database()
      .ref()
      .update(updates) */
  }

  sendFeedback(e) {
    e.preventDefault()

    /* if (this.state.comment) {
      const updates = {}
      updates['/ratings/' + this.state.ratingId + '/comment'] = this.state.comment
      firebase
        .database()
        .ref()
        .update(updates)
    } */
    this.setState({ feedbackSent: true })
  }

  commentChange(event) {
    this.setState({ comment: event.target.value })
  }

  componentDidMount() {
    /* this.setState({
      ratingId: firebase
        .database()
        .ref()
        .child('ratings')
        .push().key,
    }) */
  }

  render() {
    const { rating, feedbackSent } = this.state

    return (
      <div className="wrap">
        <Helmet bodyAttributes={{ class: ['page-done'] }}
        style={[{ "cssText": `body { background-color: ${CONFIG.client.colors[0]}; }`}]}
        />
        <div className="ticket">
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none">
            <circle cx="29" cy="29" r="29" fill="white" />
            <path
              d="M29 52C16.3174 52 6 41.6821 6 29C6 16.3179 16.3174 6 29 6C41.6826 6 52 16.3179 52 29C52 41.6821 41.6826 52 29 52Z"
              fill="#00853E"
            />
            <path
              d="M26.4142 38.4143C25.6332 39.1954 24.3668 39.1954 23.5858 38.4143L15.5859 30.4141C14.8049 29.6331 14.8049 28.3669 15.5858 27.5858V27.5858C16.3668 26.8047 17.6332 26.8046 18.4143 27.5857L25 34.1714L39.5857 19.5857C40.3668 18.8046 41.6332 18.8047 42.4142 19.5858V19.5858C43.1951 20.3669 43.1951 21.6331 42.4141 22.4141L26.4142 38.4143Z"
              fill="white"
            />
          </svg>
          <div className="header">
            <img src={CONFIG.client.logo} alt="Logo Di Mama" />
          </div>
          <div className="contents-outer">
            <div className="contents">
              {!feedbackSent && (
                <div className="rating">
                  <h2>Beoordeling levering</h2>
                  <div className="c-rating star-rating" data-rating-value={rating}>
                    {[1, 2, 3, 4, 5].map(x => (
                      <button key={x} onClick={this.onRate.bind(this, x)}>
                        {x}
                      </button>
                    ))}
                  </div>
                  {rating && rating < 3 && (
                    <form>
                      <textarea
                        name="comments"
                        placeholder="Wat hadden we beter kunnen doen?"
                        value={this.state.comment}
                        onChange={this.commentChange.bind(this)}
                        required
                      />
                      <button type="submit" onClick={this.sendFeedback.bind(this)}>
                        Verstuur
                      </button>
                    </form>
                  )}
                </div>
              )}
              {feedbackSent && (
                <div className="rating">
                  <p className="message">
                    <span role="img">🙏</span> &nbsp; Bedankt voor uw feedback
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Done
