import React from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import firebase from 'firebase/app'
import { Helmet } from 'react-helmet'
import dateFormat from 'dateformat'
import classNames from 'classnames'
import * as api from '../../api'
import { CONFIG } from '../../config'

const issuers = [
  { id: 'ing', label: 'ING' },
  { id: 'rabobank', label: 'Rabobank' },
  { id: 'abn_amro', label: 'ABN AMRO' },
  { id: 'triodos_bank', label: 'Triodos Bank' },
  { id: 'asn_bank', label: 'ASN Bank' },
  { id: 'sns_bank', label: 'SNS Bank' },
  { id: 'regiobank', label: 'RegioBank' },
  { id: 'moneyou', label: 'Moneyou' },
  { id: 'van_lanschot', label: 'Van Lanschot' },
  { id: 'bunq', label: 'bunq' },
  { id: 'knab', label: 'Knab' },
  { id: 'handelsbanken', label: 'Svenska Handelsbanken' },
]

class Ticket extends React.Component {
  state = {
    loading: true,
    error: false,
    ticket: null,
    step: 1,
  }

  parsePrice(cents, decimals = 2) {
    return `€ ${(cents / 100).toFixed(decimals).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}`
  }

  onIssuerSelect(issuerId) {
    const { match } = this.props
    const ticketId = match.params.ticketId.toLowerCase()
    api
      .createPayment(ticketId, issuerId)
      .then(body => window.location.replace(body.redirectURL))
      .catch(error => this.setState({ error }))
  }

  onOptionSelect(price) {
    var ticket = this.state.ticket
    ticket.price = price
    this.setState({ ticket })
  }

  componentDidMount() {
    const { match } = this.props
    const ticketId = match.params.ticketId.toLowerCase()

    if (ticketId) {
      firebase
        .firestore()
        .collection('Tickets')
        .doc(ticketId)
        .get()
        .then(doc => {
          if (!doc.exists) {
            this.setState({ error: 'ticket not found' })
            return
          }

          this.setState({ ticket: doc.data() })
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ loading: false }))
    }
  }

  render() {
    const { ticket, step, loading, error } = this.state
    const client = ticket ? CONFIG[ticket.client_id].client : null

    console.log(this.state)

    let optionStyles = {
      borderColor: client ? `${client.colors[0]}80 ${client.colors[0]} ${client.colors[0]} ${client.colors[0]}80` : '',
      color: client ? client.colors[0] : '',
      boxShadow: client ? `0 0 250px ${client.colors[0]}4d` : '',
    };

    let optionStylesActive = {
      borderColor: client ? `${client.colors[0]} ${client.colors[0]}80 ${client.colors[0]}80 ${client.colors[0]}` : '',
      color: client ? client.colors[0] : '',
      boxShadow: client ? `0 0 250px ${client.colors[0]}4d` : '',
      background: client ? `${client.colors[0]}26` : '',
      fontWeight: '500',
    };

    let optionPriceStyles = {
      borderColor: client ? `${client.colors[0]}80` : '',
    };

    let colorStyle = {
      color: client ? client.colors[0] : '',
    };

    return (
      <div className="view">
        <Helmet
          bodyAttributes={{ class: classNames('page-ticket unconfirmed', { loading }) }}
        />
        {error && <div className="error">Deze bon kan niet (langer) gevonden worden.</div>}
        {loading &&
          <div className="loading-screen">
            <p>Uw bon wordt geladen..</p>
          </div>
        }
        {!error && !loading &&
        <div className="ticket">
          <div className="header">
            <img src={client.logo} alt={client.name} />
            {ticket && !loading && (
              <div className="meta">
                <span className="date">{dateFormat(ticket.createdOn.toDate(), 'd mmmm yyyy')}</span>
                <span className="time">{dateFormat(ticket.createdOn.toDate(), 'HH:MM')}</span>
              </div>
            )}
          </div>
          <div className="contents">
              {ticket && ticket.rules && ticket.rules.length && (
                <div className="rules">
                  {_.map(ticket.rules, (rule, index) => (
                    <div key={`rule-${index}`} className="rule">
                      <div className="description">{rule.description}
                        <span className="amount">{rule.amount} {rule.amount > 1 ? 'stuks' : 'stuk'}</span>
                      </div>
                      <span className="price">{this.parsePrice(rule.price)}</span>
                    </div>
                  ))}
                </div>
              )}
              {ticket && ticket.options && (
                <div className="options">
                  {_.map(ticket.options, (option, index) => (
                    <div key={`option-${index}`} className="option" style={this.state.ticket.price == ticket.options[index] ? optionStylesActive : optionStyles} onClick={this.onOptionSelect.bind(this, ticket.options[index])}>
                      <div className="description">{index}</div>
                      <div className="price" style={optionPriceStyles}>{this.parsePrice(ticket.options[index], 0)}</div>
                    </div>
                  ))}
                </div>
              )}
              {ticket && ticket.price && (
                <section className="total">
                  <div className="tax">
                    {/* TODO DYNAMIC TAX PERCENTAGE */}
                    <span className="label">BTW {ticket.vat_percentage}%</span>
                    <span className="amount">{this.parsePrice(ticket.price * ticket.vat_percentage / 100)}</span>
                  </div>
                  <div className="price">
                    <span className="label" style={colorStyle}>Totaal</span>
                    <span className="amount" style={colorStyle}>{this.parsePrice(ticket.price, 0)}</span>
                  </div>
                </section>
              )}
              {ticket && ticket.price && (
                <div className="issuers">
                  <h3>Selecteer uw bank</h3>
                  {_.map(issuers, (issuer, index) => (
                    <button
                      key={`rule-${index}`}
                      data-identifier={issuer.id}
                      className={issuer.id}
                      onClick={this.onIssuerSelect.bind(this, issuer.id)}
                    >
                      {issuer.label}
                    </button>
                  ))}
                  </div>
              )}
          </div>
        </div>
      }
      </div>
    )
  }
}

export default withRouter(Ticket)
