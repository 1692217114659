import React from 'react'

// eslint-disable-next-line
import index from '../../style/ppay.sass'

function App (props) {
  return <div className="app">{props.children}</div>
}

export default App
